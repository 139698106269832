import React, { useContext, useEffect, useState } from "react";
import ModalSousCritereView from "./ModalSousCritereView";
import * as _api from "../../Apis/Criteres_apis";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "../../Helpers/Loading";
import { Button, Card, ListGroup } from "react-bootstrap";
import { userContext } from "../../Stores/Store";
import Resultats from "./Resultats";
import { BsPlus } from "react-icons/bs";

const SousCriteres = (props) => {
  const [showModalSousCritere, setShowModalSousCritere] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const user = useContext(userContext);
  const { keycloak, initialized } = useKeycloak();
  const [sousCritere, setSousCritere] = useState();
  const [sousCriteres, setSousCriteres] = useState();
  const [sousCritereId, setSousCritereId] = useState();
  const [loading, setLoading] = useState(false);
  const handleModalSousCritere = (status) => setShowModalSousCritere(status); //fonction à passer au child pour MAJ status modal
  const handleModalResult = (status) => setShowResult(status); //fonction à passer au child pour MAJ status modal

  //fonction à passer au child pour MAJ du State
  const majFromModal = (data, action) => {
    let arrayTemp = [{}];
    switch (action) {
      case "delete":
        const deleteList = sousCriteres.filter((item) => item.id != data.id);
        setSousCriteres(deleteList);
        console.log(deleteList);
        break;
      case "add":
        arrayTemp = sousCriteres;
        arrayTemp.push(data);
        setSousCriteres(arrayTemp);
        break;
      case "update":
        let userIndex = sousCriteres.findIndex((obj) => obj.id == data.id);
        arrayTemp = sousCriteres;
        arrayTemp[userIndex].name = data.name;
        setSousCriteres(arrayTemp);
        break;
      case "result":
        let userIndexBis = sousCriteres.findIndex(
          (obj) => obj.id == data.subcriteria_id
        );
        arrayTemp = sousCriteres;
        arrayTemp[userIndexBis].value = data.value;
        setSousCriteres(arrayTemp);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      _api
        .getSousCriteres(
          keycloak.token,
          props.id,
          props.username,
          user.competitionid,
          user.skillid,
          props.date
        )
        .then((res) => setSousCriteres(res))
        .then(() => setLoading(false));
    }
  }, [props.id, props.username, props.date]);

  const listSousCriteres = () => {
    return sousCriteres.map((sousCritere) => {
      let colorvalue;
      switch (sousCritere.value) {
        case 0:
          colorvalue = "round_toeval";
          break;
        case 33:
          colorvalue = "round_preparing";
          break;
        case 66:
          colorvalue = "round_near";
          break;
        case 100:
          colorvalue = "round_ready";
          break;
        default:
          break;
      }
      return (
        <ListGroup.Item style={{border:0}}>

            <a style={{cursor:"pointer"}}
              onClick={() => {
                setShowResult(!showResult);
                setSousCritereId(sousCritere.id);
              }}
            >
        <span
          className={colorvalue}
        ></span>
            </a>
            <a href="#" style={{color:"grey"}}
              onClick={() => {
                setShowModalSousCritere(true);
                setSousCritere(sousCritere);
              }}
            >
            {sousCritere.name}
            </a>
        </ListGroup.Item>
      );
    });
  };

  //Contrôle du chargement des données
  if (loading) return <Loading />;

  return (
    <div>
      {showModalSousCritere && !props.lock && (
        <ModalSousCritereView
          show={handleModalSousCritere}
          id={props.id}
          data={sousCritere}
          maj={majFromModal}
        />
      )}
      {user.userRights && user.userRights.cis.eval && showResult == true && !props.lock && (
        <Resultats
          sousCritereId={sousCritereId}
          username={props.username}
          date={props.date}
          maj={majFromModal}
          show={handleModalResult}
        />
      )}
      <ListGroup>{sousCriteres && listSousCriteres()}</ListGroup><br />
      

      {!props.lock && user.userRights && user.userRights.cis.create && (
<div className="add-souscritere">
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    setShowModalSousCritere(true);
                    setSousCritere("");
                  }}
                >
                  <BsPlus /> Sous-critère
                </Button>
                </div>
      )}
    </div>
  );
};

export default SousCriteres;
